<template>
  <div class="app-nav">
    <v-app-bar  color="#f7f6f0">
      <!-- <v-app-bar-nav-icon @click="openDrawer"></v-app-bar-nav-icon> -->
      <v-container fluid>
        <v-row>
          <v-col xs="6" sm="3" md="3" lg="3">
            <router-link class="home-link" to="/"
              >
              <img src="../../assets/logo-removebg-preview.png"  alt="Home"
              contain
              class="home-img"/>
              </router-link
            >
          </v-col>
          <v-col  xs="2" sm="7" md="7" lg="7" class="nav">
            <router-link
              v-for="(link, idx) in links"
              class="nav-link"
              :class="{
                'd-none d-sm-flex': link.name != 'Conversations',
              }"
              :to="{ name: link.urlName }"
              :key="idx"
            >
              <v-icon :icon="link.icon" />
             
            {{ link.name }}
            </router-link>
          </v-col>
          <v-col class="nav-icon"  xs="2" sm="2" md="2" lg="2">
            <v-badge class="d-none d-sm-flex" dot :color="isNotify ? 'error' : 'success'">
              <v-icon  icon="mdi-bell" @click="openDrawer" />
            </v-badge>
            <router-link class="nav-icon-link d-none d-sm-flex text-decoration-none" title="Setting" to="/settings">
              <v-icon icon="mdi-cog" />
            </router-link>
              <router-link class="nav-icon-link" @click="dialog = true" color="none" title="Logout" to="#">
                  <v-icon title="logout" icon="mdi-logout" v-bind="props"></v-icon>
                </router-link>
            
          </v-col>
        </v-row>
      </v-container>

      <v-dialog  v-model="dialog" persistent width="auto">
              
              <v-card>
                <v-card-title class="text-h5"> Logout </v-card-title>
                <v-card-text>Are you sure you want to logout?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green-darken-1" variant="text" @click="logoutHandler"> Yes </v-btn>
                  <v-btn color="green-darken-1" variant="text" @click="dialog = false"> No </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

    </v-app-bar>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { useGlobalStore } from '../../stores/global'
import auth from '../../api/auth'
export default {
  inject: ['Emitter'],
  data: () => ({
    appName: process.env.VUE_APP_NAME,
    drawer: true,
    dialog: false,
    links: [
      {
        urlName: 'conversations',
        name: 'Conversations',
        icon: 'mdi-chat',
      },
      {
        urlName: 'templates',
        name: 'Templates',
        icon: 'mdi-card-text-outline',
      },
      {
        urlName: 'templatesStatistics',
        name: 'Templates Statistics',
        icon: 'mdi-card-text-outline',
      }
    ],
  }),
  computed: {
    ...mapState(useGlobalStore, ['isNotify']),
  },
  methods: {
    ...mapActions(useGlobalStore, ['resetNotify']),
    openDrawer() {
      this.Emitter.emit('openDrawer')
      this.resetNotify()
    },
    async logoutHandler() {
      try {
        await auth.logout(`auth/logout`)
        window.$cookies.remove('jwt')
        // window.location.reload();
        this.dialog = false
        await this.$router.push({ name: 'login' })
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
<style scoped>
.home-link {
  display: block;
}
.home-img {
  width: 50%; /* Adjust width as needed */
  height: 65px; /* Maintain aspect ratio */
}
.links {
  list-style: none;
  gap: 25px;
}

.nav {
  display: flex;
  justify-content: start;
  gap: 30px;
  align-items: center;
}

.nav-icon {
  display: flex;
  justify-content: end;
  gap: 10px;
  align-items: center;

  color: #a81d22;

}

.home-link {
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  color: #ffff;
}
.nav-link {
  text-decoration: none;
  color: #a81d22;


}

.nav-icon-link {
  /* color: #ffff; */
  color: #a81d22;

}
.nav-icon .router-link-exact-active:not(:last-child),
.nav-icon .router-link-active:not(:last-child),
.nav-icon-link:hover {
  color: #968d2e;
}
.nav .router-link-exact-active,
.nav .router-link-active,
.nav-link:hover {
  border-bottom: 5px solid #968d2e;
  /* padding-right: 12px; */
  /* padding-bottom: 2px; */
}
</style>
