import api from "./api";

export default {
  index(model, pagination = false, page = 1, limit = 100, search=false){
    return api.get(`${model}`, {
      params:{pagination:pagination,page:page, limit: limit, search}});
  },
  paginate(model, page = 1, limit = 100, search='', pagination = true){
    return api.get(`${model}`, {
      params:{pagination:pagination,page:page, limit: limit, search}});
  },
  create(model, data){
    return api.post(`${model}`, data);
  },
  update(model, id,data){
    return api.patch(`${model}/${id}`, data);
  },

  show(model, id, page = 1, limit = 8){
    return api.get(`${model}/${id}`, {
      params:{page:page, limit: limit}
    });
  },

  
  destroy(model,id){
    return api.delete(`${model}/${id}`);
  },
  postWithConfig(model, data, config){
    return api.post(`${model}`, data, config);
  },

  addedFrom(from){
    return api.get(`users/from/${from}`);
  },
  campaigns(campaign){
    return api.get(`users/campaigns/${campaign}`);
  },
  campaignsFilter(filter){
    return api.post('users/campaigns/filter', filter);
  },

};
